// routes
import { ROOT_LOGIN } from "../routes/paths";
// utils
import axios from "../utils/axios";

// ----------------------------------------------------------------------

function jwtDecode(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(""),
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp: number) => {
  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert("Session Expired! Please login again");

    localStorage.removeItem("accessToken");

    window.location.href = ROOT_LOGIN;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp, role, enableMylsLink, automaticTenantCreation } =
      jwtDecode(accessToken); // ~3 days by minimals server

    localStorage.setItem("userRole", role);
    localStorage.setItem("enableMylsLink", enableMylsLink);
    localStorage.setItem("automaticTenantCreation", automaticTenantCreation);

    tokenExpired(exp);
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("enableMylsLink");
    localStorage.removeItem("automaticTenantCreation");
    localStorage.removeItem("userProfile");

    delete axios.defaults.headers.common.Authorization;
  }
};

export const getRole = () => {
  return localStorage.getItem("userRole");
};

export const getEnableMylsLink = () => {
  return localStorage.getItem("enableMylsLink");
};

export const isAutomaticTenantCreationEnabled = () => {
  return localStorage.getItem("automaticTenantCreation");
};
