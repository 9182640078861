import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";
import { ATTORNEY_API_PREFIX } from "../../utils/constants";
import { leadConversionDetails } from "../attorney-notifications/types/leads";
import {
  data,
  FilterPayload,
  InitialLeadsDetailsState,
} from "../common/common";

const initialState: InitialLeadsDetailsState = {
  isLoading: false,
  error: null,
  assignedLeadsList: { data: [] },
  claimedLeadsList: { data: [] },
  getReviewedLeadsList: { data: [] },
  getConvertedLeadsList: { data: [], total: 0 },
  payload: data,
  leadsCount: {
    data: {
      assignedLeadsCount: 0,
      claimedLeadsCount: 0,
      reviewedLeadsCount: 0,
      convertedLeadsCount: 0,
    },
  },
};

const slice = createSlice({
  name: "leadsDetailSlice",
  initialState,
  reducers: {
    // START LOADING
    startLeadDetailsLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasLeadDetailsError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ASSIGNED LEADS
    getAssignedLeadsList(state, action) {
      state.isLoading = false;
      state.assignedLeadsList = action.payload;
      state.payload = null;
    },

    // GET CLAIMED LEADS
    getClaimedLeadsList(state, action) {
      state.isLoading = false;
      state.claimedLeadsList = action.payload;
      state.payload = null;
    },

    // GET LEADS COUNT
    getLeadCount(state, action) {
      state.isLoading = false;
      state.leadsCount = action.payload;
      state.payload = null;
    },

    // CLAIM A LEAD
    claimLead(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // REJECT A LEAD
    leadRejection(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // SEND LEAD TO MYLS
    sendLeadToMyls(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // SEND REPLY TO CLIENT
    sendReplyToClient(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // GET REVIEWED LEADS
    getListofLeadsReviewedByClient(state, action) {
      state.isLoading = false;
      state.getReviewedLeadsList = action.payload;
      state.payload = null;
    },

    // CONVERT A LEAD
    convertLead(state, action) {
      state.isLoading = false;
      state.payload = action.payload;
    },

    // GET CONVERTED LEADS
    getListofLeadsConvertedByAttorney(state, action) {
      state.isLoading = false;
      state.getConvertedLeadsList = action.payload;
      state.payload = null;
    },
  },
});

//Reducer
export default slice.reducer;

const {
  startLeadDetailsLoading,
  hasLeadDetailsError,
  getAssignedLeadsList,
  getClaimedLeadsList,
  getLeadCount,
  claimLead,
  leadRejection,
  sendLeadToMyls,
  sendReplyToClient,
  getListofLeadsReviewedByClient,
  convertLead,
  getListofLeadsConvertedByAttorney,
} = slice.actions;

//--------------------------------------------------------------

// get assigned leads
export function getAssignedLeads() {
  return async (dispatch: Dispatch) => {
    dispatch(startLeadDetailsLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/assigned/leads`,
      );
      if (response.data.data) {
        dispatch(getAssignedLeadsList(response.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------

// get claimed leads
export function getClaimedLeads() {
  return async (dispatch: Dispatch) => {
    dispatch(startLeadDetailsLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/claimed/leads`,
      );
      if (response.data.data) {
        dispatch(getClaimedLeadsList(response.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------

// get leads count
export function getLeadsCount() {
  return async (dispatch: Dispatch) => {
    dispatch(startLeadDetailsLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/leads/count`,
      );
      if (response.data.data) {
        dispatch(getLeadCount(response.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------

// claim a lead
export function claimALead(leadId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.put(
        `${ATTORNEY_API_PREFIX}/claim/lead/${leadId}`,
      );
      if (response.data) {
        dispatch(claimLead(response.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------

// claim a lead
export function rejectLead(leadId: number, comment: string) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.put(
        `${ATTORNEY_API_PREFIX}/reject/lead/${leadId}?comment=${comment}`,
      );
      if (response.data) {
        dispatch(leadRejection(response.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------

// send lead to myls
export function sendLeadToMylsPortal(leadId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.put(
        `${ATTORNEY_API_PREFIX}/send/lead/${leadId}`,
      );
      if (response.data) {
        dispatch(sendLeadToMyls(response.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------

// send reply to client
export function sendReplyMailToClient(attorneysReply: any) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.post(
        `${ATTORNEY_API_PREFIX}/reply/to/client`,
        {
          leadId: attorneysReply.leadId,
          email: attorneysReply.email,
          subject: attorneysReply.subject,
          emailBody: attorneysReply.emailBody,
        },
      );
      if (response.data) {
        dispatch(sendReplyToClient(response.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------

// get assigned leads
export function getListofReviewedLeads() {
  return async (dispatch: Dispatch) => {
    dispatch(startLeadDetailsLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/reviewed/leads`,
      );
      if (response.data.data) {
        dispatch(getListofLeadsReviewedByClient(response.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------

// convert a lead
export function markLeadClaimedLeadAsConverted(
  leadConversionPayload: leadConversionDetails,
) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await axiosInstance.patch(
        `${ATTORNEY_API_PREFIX}/lead/convert`,
        {
          leadId: leadConversionPayload.leadId,
          feeQuoted: leadConversionPayload.feeQuoted,
          isVideoConsultationCompleted:
            leadConversionPayload.isVideoConsultationCompleted,
        },
      );
      if (response.data) {
        dispatch(convertLead(response.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//--------------------------------------------------------------------------------------------------------------------------

// get converted leads
export function getConvertedLeadsData(filterPayload: FilterPayload) {
  return async (dispatch: Dispatch) => {
    dispatch(startLeadDetailsLoading());
    try {
      const response = await axiosInstance.get(
        `${ATTORNEY_API_PREFIX}/converted/leads`,
        {
          params: filterPayload,
        },
      );
      if (response.data.data) {
        dispatch(getListofLeadsConvertedByAttorney(response.data.data));
      }
    } catch (error) {
      dispatch(hasLeadDetailsError(error));
    }
  };
}

//---------------------------------------------------------------------------------------------------------------------
